import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet, httpPut, httpPost } from "../../modUtils/apis/httpConfig";

// subscription_history_paginated_get
export const subscription_history_paginated_get = createAsyncThunk(
    "mod_subscription/subscription_history_paginated_get",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpGet(
                `mod_subscription/groups/${values.groupId}/subscription_histories/`,
                {
                    params: {
                        status: values.status,
                        entity_id: values.entityId,
                        subscription_id: values.subscriptionId,
                        ordering: values.ordering,
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// subscription_by_group_paginated_get
export const subscription_by_group_paginated_get = createAsyncThunk(
    "mod_subscription/subscription_by_group_paginated_get",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpGet(
                `mod_subscription/groups/${values.groupId}/subscriptions/`,
                {
                    params: {
                        is_active: values.isActive,
                        entity_id: values.entityId,
                        profil_id: values.profilId,
                        ordering: values.ordering,
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data.results;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// retourne le détail d'un d'abonnement
export const subscription_detail_by_group_get = createAsyncThunk(
    "mod_subscription/subscription_detail_by_group_get",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpGet(
                `mod_subscription/groups/${values.groupId}/subscriptions/${values.subscriptionId}/`,
                {
                    params: {
                        id: values.id,
                        created_at: values.createdAt,
                        renew_auto: values.renew_auto,
                        date_end_paid: values.dateEndPaid,
                        date_end_last_history: values.dateEndLastHistory,
                        entity: values.entity,
                        profil: values.profil
                    }
                }
            );
            return response.data;
        } catch (error) {
            // return rejectWithValue({
            //     status: error.response.status,
            //     data: error.response.data
            // });
        }
    }
);

// modifier un historique d'abonnement (mensualité d'un client)
export const subscription_history_detail_update = createAsyncThunk(
    "mod_subscription/subscription_history_detail_update",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpPut(
                `mod_subscription/groups/${values.groupId}/subscription_histories/${values.historyId}/`,
                {
                    status: values.status
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// modifier un abonnement
export const subscription_detail_by_group_update = createAsyncThunk(
    "mod_subscription/subscription_detail_by_group_update",
    async (values, {rejectWithValue}) => {
        try {
            const response = await httpPut(
                `mod_subscription/groups/${values.groupId}/subscriptions/${values.subscriptionId}/`,
                {
                    renew_auto: values.renewAuto
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const subscriptionSlices = createSlice({
    name: "subscription",
    initialState: {
        loading: true,
        subscriptions: [],
        subscriptionsHistory: [],
        subscriptionDetail: {}
    },
    extraReducers: (builder) => {
        builder
            /* subscription_history_paginated_get */
            .addCase(subscription_history_paginated_get.pending, (state, action) => {
                state.loading = true;
                state.subscriptionsHistory = [];
            })
            .addCase(subscription_history_paginated_get.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionsHistory = action.payload;
            })
            .addCase(subscription_history_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            /* subscription_by_group_paginated_get */
            .addCase(subscription_by_group_paginated_get.pending, (state, action) => {
                state.loading = true;
                state.subscriptions = [];
            })
            .addCase(subscription_by_group_paginated_get.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptions = action.payload;
            })
            .addCase(subscription_by_group_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            /* retourne le détail d'un d'abonnement */
            .addCase(subscription_detail_by_group_get.pending, (state, action) => {
                state.loading = true;
                state.subscriptionDetail = {};
            })
            .addCase(subscription_detail_by_group_get.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionDetail = action.payload;
            })
            .addCase(subscription_detail_by_group_get.rejected, (state, action) => {
                state.loading = false;
            })

            // modifier un historique d'abonnement (mensualité d'un client)
            .addCase(subscription_history_detail_update.fulfilled, (state, action) => {
                // Vérifier si `state.subscriptionsHistory.results` est un tableau
                if (Array.isArray(state.subscriptionsHistory.results)) {
                    // Trouver l'index de l'élément à mettre à jour dans `results`
                    const index = state.subscriptionsHistory.results.findIndex(
                        (subscription) => subscription.id === action.payload.id
                    );
            
                    if (index !== -1) {
                        // Mettre à jour l'élément à l'index trouvé
                        state.subscriptionsHistory.results[index] = {
                            ...state.subscriptionsHistory.results[index],
                            ...action.payload
                        };
                    }
                }
                state.loading = false;
            })
            .addCase(subscription_history_detail_update.rejected, (state, action) => {
                state.loading = false;
            })

            // modifier un abonnement
            .addCase(subscription_detail_by_group_update.fulfilled, (state, action) => {
                state.subscriptionDetail = action.payload;
                state.loading = false;
            })
            .addCase(subscription_detail_by_group_update.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export default subscriptionSlices.reducer;
