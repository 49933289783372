import { message } from "antd";
import React, { useState } from "react";
import "./SupportLinkToCustomerModal.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../modUtils/components/AlertMessage";
import { get_entity_paginated } from "../../../modEntity/slices/entitySlice";
import { update_support_detail_by_group } from "../../slices/walletLocalSlice";
import SupportCustomerInfosModal from "../supportCustomerInfosModal/SupportCustomerInfosModal";
import { Modal, Button } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import TablePaginatedScroll from "../../../modUtils/components/componentsLibrary/table/TablePaginatedScroll";

export default function SupportLinkToCustomerModal({
    support,
    modalVisible,
    setModalVisible
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [entityId, setEntityId] = useState();
    const [searchName, setSearchName] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const { groupId } = useSelector((state) => state.manager);
    const [isAddNewCustomerModalVisible, setIsAddNewCustomerModalVisible] = useState(false);


    const getFetchEntitites = async (limit, offset) => {
        const response = await dispatch(
            get_entity_paginated({
                groupId,
                limit: limit,
                offset: offset,
                name: searchName,
                type: "customer,company",
                ordering: "name"
            })
        ).unwrap();
        return response;
    };

    const handleRowClick = (rowKey) => {
      setEntityId(rowKey); //récupérer l'id de l'entité que l'utilisateur a cliqué au dessus
    };

    const handleLinkSupportToCustomer = async () => {
        // si l'utilisateur clique sur associer client alors qu'il n'a pas choisi une entité de la liste afficher un msg 'choisir une entité"
        if (!entityId) {
            message.warning({
                content: (
                  <AlertMessage alertMessage={t("supportLinkToCustomerModal.noClientsSelected")}/>
                )
            });
        } else {
            try {
              await dispatch(
                  update_support_detail_by_group({
                      groupId,
                      supportId: support?.id,
                      entity: entityId
                  })
              ).unwrap();
              setModalVisible(false);
            } catch (error) {
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={`Une erreur est survenue lors de l'asscoiation du badge N° ${support.badge_number} à ce client`}
                            errorDetail={error?.data?.detail}
                        />
                    )
                });
            } finally {
                setEntityId();
            }
        }
    };

    const handleKeyPressOnSearchEntityInput = (event) => {
        if (event.key === "Enter") {
            setSearchName(searchValue);
        }
    };

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    const columns = [
        {
            title: t("supportLinkToCustomerModal.name"),
            dataIndex: "name",
            key: "name",
            width: "50%",
            render: (text, record) => record?.first_name? record?.first_name + " " + record?.name : record?.name
        },
        {
            title: t("supportLinkToCustomerModal.email"),
            dataIndex: "email",
            key: "email",
            width: "50%",
            render: (text, record) => text
        }
    ];

    return (
        <>
        <Modal
            title={`
                ${t("supportLinkToCustomerModal.associateBadge")
                } ${support.badge_number
                } ${t("supportLinkToCustomerModal.withCustomer")}
            `}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            width={"60%"}
            onCancel={() => setModalVisible(false)}
            onOk={handleLinkSupportToCustomer}
            okText={
                <span>
                    {t("supportLinkToCustomerModal.associateCustomer")}
                    <img src={process.env.PUBLIC_URL + "/img/design/link.svg"}/>
                </span>
            }
        >
            <div className="flex flex-row justify-between items-center mb-4">
                <div className="search-site flex flex-row">
                    <input
                        id="searchInput"
                        type="text"
                        className="search-site"
                        placeholder={t("supportLinkToCustomerModal.customerCompany")}
                        value={searchValue}
                        onChange={handleChange}
                        onKeyPress={handleKeyPressOnSearchEntityInput}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
                        alt="zoom"
                    />
                </div>

                <Button
                    type="primary"
                    onClick={() => setIsAddNewCustomerModalVisible(true)}
                >
                    {t("supportLinkToCustomerModal.newCustomer")}
                </Button>
            </div> 

            <TablePaginatedScroll
                fetch={getFetchEntitites}
                effect={searchName}
                columns={columns}
                onRowClick={handleRowClick}
                rowClassName={(record, index) =>
                    record.id == entityId ? "table-row-clicked" : ""
                }
            />
        </Modal>

        {isAddNewCustomerModalVisible && (
            <SupportCustomerInfosModal
                modalVisible={isAddNewCustomerModalVisible}
                setModalVisible={setIsAddNewCustomerModalVisible}
            />
        )}
      </>
    );
};
