import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { message, Checkbox } from "antd";
import { eEquipmentType } from "../../../commun_global/enums";
import {
    get_constraint_financial_paginated,
    delete_constraint_financial_detail
} from "../slices/constraintFinancialSlice";
import { ReactComponent as DeleteIcon } from "../../modEntity/assets/images/trash-can.svg";
import { getPeriodLabel } from "../functions/handleDaysOfWeek";
import AddFinancialConstraintModal from "./AddFinancialConstraintModal";
import { update_configuration_detail_by_profil } from "../slices/configurationSlice";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { amountFormat } from "../../modUtils/functions/numberFormat";
import ButtonAdd from "../../modUtils/components/buttons/ButtonAdd";
import { Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import TablePaginatedScroll from "../../modUtils/components/componentsLibrary/table/TablePaginatedScroll";

export default function FinancialConstraints({
    selectedProfilId,
    selectedConfigId,
    configuration,
    getConfigurationDetail
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadingTable, setLoadingTable] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [isAddFinancialConstraintModalVisible, setIsAddFinancialConstraintModalVisible] = useState(false);
    const [idConfigue, setIdConfigue] = useState(selectedConfigId);

    // récupérer la liste des contraintes finanicères
    const getConstraintFinancial = async (limit, offset) => {
        const response = await dispatch(
            get_constraint_financial_paginated({
                groupId,
                configurationId: selectedConfigId,
                limit,
                offset
            })
        ).unwrap();
        
        return response;
    };

    useEffect(() => {
        setIdConfigue(selectedConfigId);
        if (selectedConfigId !== idConfigue) {
            groupId && loadingTable && getConstraintFinancial();
            setLoadingTable(false);
        }
    }, [groupId, loadingTable]);

    // supprimer une contrainte financière
    const deleteConstraintFinancial = async (constraintId) => {
        setLoadingTable(true);
        await dispatch(
            delete_constraint_financial_detail({
                groupId,
                configurationId: selectedConfigId,
                constraintId
            })
        ).unwrap();
        setLoadingTable(false);
    };

    const columns = [
        {
            title: "financialConstraints.equipmentType",
            dataIndex: "equipment_type",
            key: "equipment_type",
            width: "40%",
            align:"left",
            render: (text, record) => <div className="text-center">{text === null ? "Tous" : t(eEquipmentType[text])}</div>
        },
        {
            title: "financialConstraints.periodType",
            dataIndex: "period_type",
            key: "period_type",
            width: "20%",
            render: (text, record) => getPeriodLabel(text)
            // TODO CH enum e_period_type
        },
        {
            title: "financialConstraints.limitIn",
            dataIndex: "amount_max",
            key: "amount_max",
            width: "20%",
            align:"right",
            render: (text, record) => <div className="text-center">{amountFormat(text)}</div>
        },
        ...(configuration.is_future
            ? [
                {
                    title: "",
                    dataIndex: "id",
                    key: "id",
                    width: "10%",
                    render: (text) => (
                        <div
                            className="w-[15px] m-auto cursor-pointer"
                            onClick={() => deleteConstraintFinancial(text)}
                        >
                            <DeleteIcon/>
                        </div>
                    )
                }
            ]
            : [])
    ];

    // appelé au changement de checkbox pour activer ou désactiver les contraintes financières
    const handleOnChangeCheckbox = async () => {
        try {
            const response =  await dispatch(
                update_configuration_detail_by_profil({
                    groupId,
                    profilId: selectedProfilId,
                    configurationId: selectedConfigId,
                    constraintsFinancialIsActive: !configuration?.constraintfinancial_is_active
                })
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la (dé)activation des contraintes financières !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        }

        getConfigurationDetail(selectedProfilId, selectedConfigId);
    };

    return (
        <>
        <div className="flex items-center justify-center">
            {configuration?.is_future == true && (
                <>
                <div className="pb-2">
                    <Checkbox
                        checked={configuration.constraintfinancial_is_active}
                        onChange={handleOnChangeCheckbox}
                    >
                    </Checkbox>
                </div>
            

                <span className="text-primary-color font-bold ml-2">
                    {t("financialConstraints.enableFinancialConstraints")}
                </span>
                </>
            )}
        </div>

        {configuration.constraintfinancial_is_active &&
            <>
            <div className="text-center font-bold text-sm my-6">
                {t("financialConstraints.financialConstraints")}
            </div>
            
            {configuration?.is_future == true && (
                <div className="justify-center items-center">
                    <ButtonAdd
                        label={t("financialConstraints.addFinancialConstraint")}
                        className="mb-4"
                        onClick={() => setIsAddFinancialConstraintModalVisible(true)}
                    />
                </div>
            )}

            {loadingTable ? <Spinner/> :
                <TablePaginatedScroll
                    fetch={getConstraintFinancial}
                    columns={columns}
                />
            }

            {isAddFinancialConstraintModalVisible && (
                <AddFinancialConstraintModal
                    isModalVisible={isAddFinancialConstraintModalVisible}
                    setIsModalVisible={setIsAddFinancialConstraintModalVisible}
                    selectedConfigId={selectedConfigId}
                    setLoadingTable={setLoadingTable}
                />
            )}
            </>
        }
        </>
    );
};
