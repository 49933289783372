import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    get_profil_paginated,
    create_profil_by_entity,
    delete_profil_by_entity
} from "../slices/profilSlice";
import { ReactComponent as NotCheckIcon } from "../../modUtils/assets/images/notCheckIcon.svg";
import { Card, ErrorMessage, Modal } from "ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary";

export default function ProfilByEntityCreateModal({
    entity,
    isModalEditProfilVisible,
    setIsModalEditProfilVisible
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { entityDetail } = useSelector((state) => state.entity);
    const { profils } = useSelector((state) => state.profil.profil);
    const [selectedProfilId, setSelectedProfilId] = useState();
    const [messageError, setMessageError] = useState(null);

    // TODO CH FAIRE LA PAGINATION
    async function getProfils() {
        await dispatch(get_profil_paginated({ groupId, limit: 100, offset: 0 }));
    }

    useEffect(() => {
        getProfils();
    }, [groupId]);

    const linkProfilToEntity = async () => {
        if (selectedProfilId !== null) {
            if (entityDetail?.profil) {
                await dispatch(delete_profil_by_entity({ groupId, entityId: entity?.id }));
            }
            const response = await dispatch(create_profil_by_entity({ groupId, entityId: entity?.id, profil: selectedProfilId }));
            if (response.payload.status === 400) {
                setMessageError(response.payload.data.detail)
            }else {
                setIsModalEditProfilVisible(false);
                setMessageError(null)
            }
        }else {
            await dispatch(delete_profil_by_entity({ groupId, entityId: entity?.id }));
            setIsModalEditProfilVisible(false);
        }
    };

    // fonction à appeler quand on clique sur le bouton Valider
    const handleOnOkButtonClick = () => {
        linkProfilToEntity();
    };

    return (
        <Modal
            title={t("profilByEntityCreateModal.profiles")}
            isOpen={isModalEditProfilVisible}
            onClose={() => setIsModalEditProfilVisible(false)}
            onOk={() => handleOnOkButtonClick()}
            onOkButtonDisabled={selectedProfilId === entityDetail?.profil?.id || selectedProfilId === "undefined" || profils?.length === 0}
            okText={t("profilByEntityCreateModal.validate")}
        >
            <div className="text-center font-medium text-sm mb-8">
                {profils?.filter((profil) => profil.service_type === 0).length > 0
                    ? t("profilByEntityCreateModal.PleaseChooseProfileFromListBelow")
                    : t("profilByEntityCreateModal.noProfile")
                }
            </div>

            {profils?.filter((profil) => profil.service_type === 0)
                .map((profil) => (
                    <div onClick={() => setSelectedProfilId(profil.id)}>
                        <Card
                            key={profil?.id}
                            className={`
                                rounded-md my-1.5 cursor-pointer
                                ${profil?.id === selectedProfilId && "bg-blue-100 border-blue-500"}
                                ${profil?.id === entityDetail?.profil?.id && "border-blue-500 border-2"}
                            `}
                        >
                            <div className="flex flex-row justify-between items-center min-h-10 font-semibold text-sm">
                                <div className="flex flex-row items-center">
                                    <div className="w-8 mr-2">
                                        <img
                                            src={profil?.service_type_image}
                                            alt={profil?.service_type_label}
                                        />
                                    </div>

                                    <div>{profil?.name}</div>
                                </div>

                                <div className="text-primary-color">
                                    {profil?.id === entityDetail?.profil?.id && t("profilByEntityCreateModal.active")}
                                    {(profil?.id === selectedProfilId && profil?.id !== entityDetail?.profil?.id) &&
                                        t("profilByEntityCreateModal.selected")
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                )
            )}
            
            {entityDetail?.profil?.id && 
                <div onClick={() => setSelectedProfilId(null)}>
                    <Card
                        className={`
                            rounded-md my-1.5 cursor-pointer
                            ${null === selectedProfilId && "bg-blue-100 border-blue-500"}
                            ${null === entityDetail?.profil?.id && "border-blue-500 border-2"}
                        `}
                    >
                        <div className="flex flex-row justify-between items-center min-h-10 font-semibold text-sm">
                            <div className="flex flex-row items-center">
                                <div className="w-8 mr-2">
                                    <NotCheckIcon/>
                                </div>
                                
                                <div>{t("profilByEntityCreateModal.unlinkYourProfile")}</div>
                            </div>

                            <div className="text-primary-color">
                                {null === entityDetail?.profil?.id && t("profilByEntityCreateModal.active")}
                                {(null === selectedProfilId && null !== entityDetail?.profil?.id) &&
                                    t("profilByEntityCreateModal.selected")
                                }
                            </div>
                        </div>
                    </Card>
                </div>
            }

            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
        </Modal>
    );
};
