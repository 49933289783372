import React, { useState, useEffect } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Button,
  Alert,
  AlertMessage,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import {
  delete_credit_by_equipment,
  get_credit_by_equipment,
  create_credit_by_equipment,
} from "../../../modFixing/slices/fixingSlice";
import PermissionDeniedPage from "../../../modUtils/components/errorPage/PermissionDeniedPage";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";
import { message, Tooltip } from "antd";
import "./EquipmentCredit.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ButtonDisabled from "../../../modUtils/components/buttons/ButtonDisabled";

export default function EquipmentCredit({ equipment, siteId }) {
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const { equipmentCredit } = useSelector((state) => state.fixing);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [error, setError] = useState(null);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission(
    "mod_equipment:can_fixing",
    permissions
  );
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission(
    "mod_equipment:can_fixing",
    groupPermissions
  );
  const getCreditByEquipment = async () => {
    setInputValue();
    try {
      setLoading(true);
      await dispatch(
        get_credit_by_equipment({
          groupId,
          siteId,
          equipmentId: equipment.id,
        })
      ).unwrap();
    } catch (error) {
      setError(error?.data?.detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (verifyPermission && groupId && siteId && equipment) {
      getCreditByEquipment();
    }
  }, [equipment.id, i18next.language]);
  const deleteCreditEquipment = async () => {
    setLoading(true);
    try {
      await dispatch(
        delete_credit_by_equipment({
          groupId,
          siteId,
          equipmentId: equipment.id,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("createCreditEquipment.errorMessage")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  const createCreditEquipment = async (type) => {
    try {
      setLoading(true);
      await dispatch(
        create_credit_by_equipment({
          groupId,
          siteId,
          equipmentId: equipment.id,
          value: inputValue,
        })
      ).unwrap();
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("createCreditEquipment.errorMessage")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
      setInputValue();
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <>
      {!verifyPermission ? (
        <PermissionDeniedPage size="sm" permission={verifyGroupPermission} />
      ) : (
        <div className="flex-display flex-col items-center max-w-ws mx-auto space-y-4 p-4">
          {error && (
            <AlertMessage statusMessage="error" defaultMessage={error} />
          )}
          <div className="flex items-center relative mt-2 left-4">
            <Input
              label={t("createCreditEquipment.creditActive")}
              name="creditActif"
              value={equipmentCredit}
              disabled
            />
            <img
              className="left-2 mt-2 cursor-pointer"
              src={process.env.PUBLIC_URL + "/img/refreshIcon.png"}
              alt="refresh"
              onClick={() => getCreditByEquipment()}
            />
          </div>
          {error ? (
            <Tooltip title={error}>
              <span>
                {" "}
                <ButtonDisabled>
                  {t("createCreditEquipment.deleteCredit")}
                </ButtonDisabled>
              </span>
            </Tooltip>
          ) : (
            <Button
              type="primary"
              onClick={deleteCreditEquipment}
              htmltype="reset"
              style={{
                backgroundColor: "#FF0000",
                borderColor: "#FF0000",
                height: "40px",
              }}
            >
              {t("createCreditEquipment.deleteCredit")}
            </Button>
          )}

          <div
            className="flex-display flex-column align-items-center "
            style={{ marginTop: "10%" }}
          >
            <div className="right-4">
              {" "}
              <Input
                type="number"
                label={t("createCreditEquipment.AmountToCredit")}
                defaultValue={inputValue}
                name="montantACrediter"
                onChange={handleChange}
              />
            </div>
            {error ? (
              <Tooltip title={error}>
                <span>
                  <ButtonDisabled>
                    {t("createCreditEquipment.creditButton")}
                    <ArrowRightOutlined />
                  </ButtonDisabled>
                </span>
              </Tooltip>
            ) : (
              <Button
                htmltype="submit"
                type="primary"
                onClick={createCreditEquipment}
                style={{
                  backgroundColor: "#36C31D",
                  borderColor: "#36C31D",
                  height: "40px",
                }}
              >
                {t("createCreditEquipment.creditButton")} <ArrowRightOutlined />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
