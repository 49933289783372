import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { get_constraint_identification } from "../slices/constraintIdentificationSlice";
import Button from "../../modUtils/components/buttons/Button";
import AlertMessage from "../../modUtils/components/AlertMessage";
import { Label, Spinner } from "../../modUtils/components/componentsLibrary/componentsLibrary";

export default function IdentificationConstraints({
    setSelectedOptions,
    selectedOptions,
    selectedConfigId,
    configurationDetail
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { groupId } = useSelector((state) => state.manager);

    const SupportFormatToDisplay = Object.freeze({
        1: 'eSupportFormat.badgeMifare',
        2: 'eSupportFormat.qrCode'
    });
      
    // récupérer la liste des contraintes d'identification
    const getConstraintsIdentification = async () => {
        try {
 
            const response = await dispatch(
                get_constraint_identification({
                    groupId,
                    configurationId: selectedConfigId
                })
            ).unwrap();

            if (response === "Object Not found") {
                setSelectedOptions(Object.keys(SupportFormatToDisplay).map(Number));
            } else if (response?.formats_authorized) {
                setSelectedOptions(response?.formats_authorized);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la récupération des contraintes d'identification !`}
                        errorDetail={error?.data?.detail}
                    />
                )
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        selectedConfigId && getConstraintsIdentification();
    }, [selectedConfigId, groupId]);

    const handleSelect = (index) => {
        setSelectedOptions(prevState => {
            if (Array.isArray(prevState)) {
                if (prevState?.includes(index)) {
                    return prevState.filter(option => option !== index);
                } else {
                    return [...prevState, index];
                }
            } else if (typeof prevState === "number") {
                if ([prevState].includes(index)) {
                    return [prevState].filter(option => option !== index);
                } else {
                    return [prevState, index];
                }
            }
        });
    };

    return (
        <>
        {loading ? <Spinner/> :
            <div className="flex flex-col justify-start items-start w-max m-auto">
                <Label label="identificationConstraints.identification"/>

                <div className="flex flex-row">
                    {Object.keys(SupportFormatToDisplay).map((key) => {
                        const format = parseInt(key);

                        // Vérifie si le format est autorisé dans les contraintes récupérées
                        const isAuthorized = selectedOptions?.includes(format);

                        return (
                            <Button
                                key={key}
                                className={`${!configurationDetail?.is_future && "!cursor-not-allowed"} m-2`}
                                color={isAuthorized ? (selectedOptions.includes(format) ? "primary" : "outline") : "outline"}  // Définit la couleur en fonction du statut
                                onClick={configurationDetail?.is_future ? () => handleSelect(format) : null}  // Désactive le clic si "is_future" est false
                                disabled={!isAuthorized}  // Désactive le bouton si le format n'est pas autorisé
                            >
                                {t(SupportFormatToDisplay[format])}
                            </Button>
                        );
                    })}
                </div>
            </div>
        }
        </>
    );
};
