import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut
} from "ck_commun/src/app/modUtils/apis/httpConfig";

// retourner la liste des profils
export const get_profil_paginated = createAsyncThunk(
    "mod_profil/get_profil_paginated",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/profils/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        is_active: values.isActive,
                        name: values.name,
                        ordering: values.ordering
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// créer un profil
export const create_profil = createAsyncThunk(
    "mod_profil/create_profil",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/profils/`,
                {
                    name: values.name,
                    type: values.type,
                    service_type: values.serviceType,
                    is_public: values.isPublic,
                    is_active: values.isActive
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// retourner la liste des profils par entité
export const get_profil_by_entity = createAsyncThunk(
    "mod_profil/get_profil_by_entity",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/entities/${values.entityId}/profil/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Associer un profil a une entité
export const create_profil_by_entity = createAsyncThunk(
    "mod_profil/create_profil_by_entity",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/entities/${values.entityId}/profil/`,
                {
                    profil: values.profil
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// Suppression d'un lien entre une entité et un profil
export const delete_profil_by_entity = createAsyncThunk(
    "mod_profil/delete_profil_by_entity",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/entities/${values.entityId}/profil/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// retourner le détail d'un profil
export const get_profil_detail = createAsyncThunk(
    "mod_profil/get_profil_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

// modifier un profil
export const update_profil_detail = createAsyncThunk(
    "mod_profil/update_profil_detail",
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}/`,
                {
                    name: values.name,
                    is_active: values.isActive,
                    is_public: values.isPublic
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data
            });
        }
    }
);

const profilSlice = createSlice({
    name: "profil",
    initialState: {
        profils: [],
        profilsCount: 0,
        loading: true,
        profilDetail: {},
        profilByEntity: {}
    },
    reducers: {
        update_profil_configuration_count: (state, action) => {
            const profilIndex = state.profils.findIndex(profil => profil.id === action.payload.profilId);
            if (profilIndex !== -1) {
                state.profils[profilIndex].configurations_count += 1;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            /* get_profil_paginated */
            .addCase(get_profil_paginated.pending, (state, action) => {
                state.profils = [];
            })
            .addCase(get_profil_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.profils = action.payload.results;
                state.profilsCount = action.payload.count;
            })
            .addCase(get_profil_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            /* create_profil*/
            .addCase(create_profil.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.profils = [...state.profils, {...action.payload, configurations_count:0}];
            })
            .addCase(create_profil.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_profil_by_entity */
            .addCase(get_profil_by_entity.pending, (state, action) => {
                state.profilByEntity = {};
            })
            .addCase(get_profil_by_entity.fulfilled, (state, action) => {
                state.loading = false;
                state.profilByEntity = action.payload;
            })
            .addCase(get_profil_by_entity.rejected, (state, action) => {
                state.loading = false;
            })

            /* create_profil_by_entity*/
            .addCase(create_profil_by_entity.fulfilled, (state, action) => {
                state.loading = false;
                state.profilByEntity = action.payload;
            })
            .addCase(create_profil_by_entity.rejected, (state, action) => {
                state.loading = false;
            })

            /* delete_profil_by_entity*/
            .addCase(delete_profil_by_entity.fulfilled, (state, action) => {
                state.loading = false;
                state.profilByEntity = {};
            })
            .addCase(delete_profil_by_entity.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_profil_detail */
            .addCase(get_profil_detail.pending, (state, action) => {
                state.profilDetail = {};
            })
            .addCase(get_profil_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.profilDetail = action.payload;
            })
            .addCase(get_profil_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_profil_detail*/
            .addCase(update_profil_detail.fulfilled, (state, action) => {
                state.loading = false;        
                //update profils
                const updatedProfilsList = state.profils.map((profil) => (
                    profil.id === action.payload.id ? {...profil ,...action.payload} : profil
                ));
                state.profils = updatedProfilsList;
                //update profil detail
                if (state.profilDetail.id == action.payload.id) {
                    state.profilDetail = action.payload;
                }
            })
            .addCase(update_profil_detail.rejected, (state, action) => {
                state.loading = false;
            });
    }
});

export const { update_profil_configuration_count } = profilSlice.actions;

export default profilSlice.reducer;
