import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePaginatedScroll from "../../modUtils/components/componentsLibrary/table/TablePaginatedScroll";
import { Modal } from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { support_centralized_paginated_get } from "../../modSupportCentralized/slices/supportCentralizedSlices";

export default function EntitySupports({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const [modalVisible, setModalVisible] = useState(false);
    const [ supports, setSupports] = useState(null);

    function handleClick(supports) {
        setSupports(supports)
        setModalVisible(true)
    };

    const columns = [
        {
            title: t("entitySupports.type"),
            dataIndex: "support_format",
            key: "support_format",
            width: "25%"
        },
        {
            title: t("entitySupports.supportNumber"),
            dataIndex: "support_number",
            key: "support_number",
            width: "25%"
        },
        {
            title: t("entitySupports.lastUsed"),
            dataIndex: "last_visit_date",
            key: "last_visit_date",
            render: (text, record) => record.last_visit_date ? record.last_visit_date : t("entitySupports.none"),
            width: "25%"
        },
        {
            title: t("entitySupports.qrCode"),
            dataIndex: "support_dematerialized",
            key: "support_dematerialized",
            render: (text, record) => text && <img onClick={() => handleClick(record)} className="w-8" src={text}/>,
            width: "25%"
        }
    ];

    const getSupportTransactions = async (limit, offset) => {
        const response = await dispatch(
            support_centralized_paginated_get({
                groupId: groupId,
                entityId: entity?.id,
                limit,
                offset
            })
        ).unwrap();
        return response;
    };

    return (
        <>
        {entity && 
            <>
            <TablePaginatedScroll
                label={t("entitySupports.identificationAndPaymentMedia")}
                fetch={getSupportTransactions}
                effect={entity}
                columns={columns}
            />
            <Modal
                title={t("entitySupports.qrCode")}
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
            >
                <img src={supports?.support_dematerialized}/>
            </Modal>
            </>
        }
        </>
    );
};
