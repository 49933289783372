import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProfilCard from "../../modProfil/components/ProfilCard";
// import CardOutlineIcon from "../../modUtils/components/card/CardOutlineIcon";
import ProfilByEntityCreateModal from "../../modProfil/components/ProfilByEntityCreateModal";
import { dateFormat } from "../../modUtils/functions/dateFormat";
import { ReactComponent as EditIcon } from "../assets/images/pen.svg";
import SubscriptionModal from "../../modSubscription/components/SubscriptionModal";
// import { ReactComponent as SchedulesIcon } from "../../modProfil/assets/images/horloge.svg";
// import { ReactComponent as FinancialIcon } from "../assets/images/euro.svg";
// import { ReactComponent as ServiceIcon } from "../assets/images/pointer.svg";
// import { ReactComponent as CheckCircleIcon } from "../assets/images/check.svg";
// import { ReactComponent as SchedulesIconActive } from "../assets/images/horloge-bg-white.svg";
// import { ReactComponent as FinancialIconActive } from "../assets/images/euro-bg-white.svg";
// import { ReactComponent as ServiceIconActive } from "../assets/images/pointer-bg-white.svg";
// import { ReactComponent as DiscountIconActive } from "../assets/images/pourcentage-bg-white.svg";
// import ScheduleAndPeriodConstraints from "../../modProfil/components/ScheduleAndPeriodConstraints";
// import FinancialConstraints from "../../modProfil/components/FinancialConstraints";
// import ServiceConstraints from "../../modProfil/components/ServiceConstraints";

export default function EntityProfil({ entity }) {
    const { t } = useTranslation();
    const { entityDetail } = useSelector((state) => state.entity);
    const { profilDetail } = useSelector((state) => state.profil.profil);
    const [isModalEditProfilVisible, setIsModalEditProfilVisible] = useState(false);
    // const [isModalScheduleAndPeriodVisible, setIsModalScheduleAndPeriodVisible] = useState(false);
    // const [isModalFinanacialVisible, setIsModalFinanacialVisible] = useState(false);
    // const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);

    return (
        <>
        <div className="flex flex-col justify-center items-center">
            <ProfilCard
                cardTitle={t("entityProfil.activeProfile")}
                cardInfo={entityDetail?.profil?.name}
                cornerIcon={<EditIcon/>}
                onClickCornerIcon={() => setIsModalEditProfilVisible(true)}
                additionalCardInfo={
                    entityDetail?.profil
                        ? profilDetail?.active_configuration?.date_activation &&
                        `${t("entityProfil.sinceThe")} ${dateFormat(
                            profilDetail?.active_configuration?.date_activation,
                            "DD/MM/YYYY"
                        )}`
                        : t("entityProfil.pleaseChooseProfile")
                }
            />

            <SubscriptionModal
                entity={entity}
            />

            {/* TODO CH cette partie n'est pas encore terminée dans le back-end */}
            {/* {entityDetail?.profil &&
                <div className="mt-20">
                    <div className="text-center font-bold text-sm mb-8">
                        Contraintes personnalisés
                    </div>

                    <div className="grid grid-row-1 grid-cols-3 gap-2">
                        <div className="col-span-3 xl:col-span-1">
                            <CardOutlineIcon
                                color="#F53C56"
                                label="Horaires"
                                iconDefault={<SchedulesIcon />}
                                iconIsActive={<SchedulesIconActive />}
                                active
                                // disabled
                                onClick={() => setIsModalScheduleAndPeriodVisible(true)}
                            />
                        </div>

                        <div className="col-span-3 xl:col-span-1">
                            <CardOutlineIcon
                                color="#0088FF"
                                label="Financier"
                                iconDefault={<FinancialIcon />}
                                iconIsActive={<FinancialIconActive />}
                                //cornerIcon={<CheckCircleIcon />}
                                active
                                // disabled
                                onClick={() => setIsModalFinanacialVisible(true)}
                            />
                        </div>

                        <div className="col-span-3 xl:col-span-1">
                            <CardOutlineIcon
                                color="#008CB3"
                                label="Service"
                                iconDefault={<ServiceIcon/>}
                                iconIsActive={<ServiceIconActive/>}
                                active
                                // disabled
                                onClick={() => setIsModalServiceVisible(true)}
                            />
                        </div>
                    </div>
                </div>
            } */}
        </div>

        {isModalEditProfilVisible && (
            <ProfilByEntityCreateModal
                entity={entity}
                isModalEditProfilVisible={isModalEditProfilVisible}
                setIsModalEditProfilVisible={setIsModalEditProfilVisible}
            />
        )}

        {/* TODO CH cette partie n'est pas encore terminée dans le back-end */}
        {/* {isModalScheduleAndPeriodVisible && (
            <Modal
                title={t("entityProfil.timeConstraints")}
                isOpen={isModalScheduleAndPeriodVisible}
                onClose={() => setIsModalScheduleAndPeriodVisible(false)}
                width="1000px"
                height="800px"
            >
                <ScheduleAndPeriodConstraints
                    configurationId={profilDetail?.active_configuration?.id}
                />
            </Modal>
        )}

        {isModalFinanacialVisible && (
            <Modal
                isOpen={isModalFinanacialVisible}
                onClose={() => setIsModalFinanacialVisible(false)}
            >
                <FinancialConstraints
                    configurationId={profilDetail?.active_configuration?.id}
                />
            </Modal>
        )}
        
        {isModalServiceVisible && (
            <Modal
                isOpen={isModalServiceVisible}
                onClose={() => setIsModalFinanacialVisible(false)}
            >
                <ServiceConstraints
                    configurationId={profilDetail?.active_configuration?.id}
                />
            </Modal>
        )} */}
        </>
    );
};
