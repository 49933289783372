import React from "react";

export default function Toggle({ label, checked, onChange }) {
    return (
        <label className="inline-flex items-center cursor-pointer">
            <span className="mr-4">{label}</span>

            <input 
                type="checkbox" 
                checked={checked} 
                onChange={onChange} 
                className="sr-only peer" 
            />

            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-color dark:peer-focus:ring-primary-color rounded-full peer dark:bg-gray-700 peer-checked:bg-primary-color">
                <div className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform ${checked ? 'translate-x-full' : ''}`}></div>
            </div>
        </label>
    );
};
